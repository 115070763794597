import "./App.css";
import React, { useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import useStyles from './Styles.js';
import { getJobs } from './actions/jobs';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

// import Footer from './components/Footer/Footer';
import AuraAppHeader from './components/AuraAppHeader/AuraAppHeader';
import Home from './pages/Home/Home.js';
import About from './pages/About/About.js';
import Team from './pages/Team/Team.js';
import Contact from './pages/Contact/Contact.js';
import Login from './pages/Login/Login.js';
import ExperiencesPg from './pages/Experiences/ExperiencesPg';
import HimalayanPg from './pages/Himalayan/HimalayanPg'
import AbledPg from './pages/AbledTraveller/AbledPg';
import Enquire from './components/Enquire/EnquireForm1'
import Chat from './components/Chat/Chat';
import BeResponsiblePg from './pages/BeResponsible/BeResponsiblePg';

import ExpDetailPg1 from './pages/Experiences/ExpDemo/ExpDetailPg1';
import ExpDetailPg2 from './pages/Experiences/ExpDemo/ExpDetailPg2';
import ExpDetailPg3 from './pages/Experiences/ExpDemo/ExpDetailPg3';
import ExpDetailPg4 from './pages/Experiences/ExpDemo/ExpDetailPg4';
import ExpDetailPg5 from './pages/Experiences/ExpDemo/ExpDetailPg5';
import ExpDetailPg6 from './pages/Experiences/ExpDemo/ExpDetailPg6';
import ExpDetailPg7 from './pages/Experiences/ExpDemo/ExpDetailPg7';
import ExpDetailPg8 from './pages/Experiences/ExpDemo/ExpDetailPg8';


import France2024 from "./pages/LandingPages/France2024.js";




const App = () => {

  const [ lndPgActv, setLndPgActv ] = useState (true);





  const classes = useStyles(); 
  const dispatch = useDispatch();

  useEffect( () => { 
    dispatch(getJobs());
  }, [dispatch]);


  return (
    <div className="App">
      
      <Router>
         
          <AuraAppHeader />
          
         
          <Routes> 
            
            <Route exact path='/' element={<Home />} />  
            <Route exact path='/experiences' element={<ExperiencesPg />} />
            <Route exact path='/abled-voyages' element={<AbledPg />} />
            <Route exact path='/himalayan-voyages' element={<HimalayanPg />} />
            <Route exact path='/be-responsible' element={<BeResponsiblePg />} />

            <Route path='/about-aura'  element ={<About />} />  
            
            <Route path='/team'  element ={<Team />} />  
           
            <Route path='/contact'  element ={<Contact />} />  

            <Route path='/login'  element ={<Login />} />  
            <Route path='/chat'  element ={<Chat />} />  
            <Route path='/enquire'  element ={<Enquire />} />  


            <Route path='/experience1'  element ={<ExpDetailPg1 />} /> 
            <Route path='/experience2'  element ={<ExpDetailPg2 />} /> 
            <Route path='/experience3'  element ={<ExpDetailPg3 />} /> 
            <Route path='/experience4'  element ={<ExpDetailPg4 />} /> 
            <Route path='/experience5'  element ={<ExpDetailPg5 />} /> 
            <Route path='/experience6'  element ={<ExpDetailPg6 />} /> 
            <Route path='/experience7'  element ={<ExpDetailPg7 />} /> 
            <Route path='/experience8'  element ={<ExpDetailPg8 />} /> 
            
            <Route path='/france2024'  element ={<France2024 />} /> 

          </Routes>

          
          {/* <Footer /> */}

      </Router>
    
      
      
    </div>
  );
}

export default App;

