import React from 'react'
import { Link } from 'react-router-dom';
import Home from '../Home/Home';
import ImgTaj from './TajMahal.jpg'
import HawaMahal from './Images/hawamahal.jpg'
import SouthIndia from './Images/southindiatemple.jpg'
import GoldenTemple from './Images/goldentemple.jpg'
import Nepal from './Images/nepal.jpg'
import Background1 from './Images/Background1.jpg'

import T1 from './Images/Vandana.jpg'
import T2 from './Images/Ravinder2.jpg'
import T3 from './Images/Arun.jpg'
import T4 from './Images/Virender.jpg'
import T5 from './Images/Rakesh.jpg'
import T6 from './Images/Ravinder.jpg'
import T7 from './Images/Philippe.png'

import FlagInd from './Images/FlagInd.png'
import FlagFr from './Images/FlagFr.png'







import AuraLogo from './Auralogovert.svg'
import HimLogo from './ImgLogoHim.svg'
import AbledLogo from './Abledlogovert.svg'

import Icon1 from './icon1.svg'
import Icon2 from './icon2.svg'
import Icon3 from './icon3.svg'
import IndeNepalLogo from './ImgINDENEPALLOGO.svg'



import "./Styles.css";

function France2024() {
  return (


    <>
    <br /> <br /><br /><br /><br /><br />

    <div className='GoToHomeBtCont'> 
        <div className='GoToHomeBt' onClick={Home} > Home </div>
    </div>


    <div className='HeaderFrance24'> 

        <div className='Section1'>              

            <div className='Head1'>    FIT - TRIBUS - GROUPES           </div>
            <div className='Head2'>  <img alt="nothing" className='LogoINDENEPAL' src={IndeNepalLogo}  />  </div>
            <div className='Subhead1'>    3 GAMMES & 22 CIRCUITS - PRETS A VENDRE!  </div>
        </div>  

        
        

        <div className='Section2'> 

                <div className='Subhead2'>   3 GAMMES POUR REPONDRE A TOUS BESOINS   </div>

                <div className='Sect2ColCont'>  
                
                    <div className="Colm">            
                    <div className='SubHeadSec2'> <div className="IconCont">  <img alt="nothing" src={Icon1} /> </div> DISCOVER </div> 
                    <div className='BodyTextSec2'> Des circuits panoramiques très complets.                    </div>
                    </div> 
                    
                    <div className="Colm"> 
                    <div className='SubHeadSec2'> <div className="IconCont">  <img alt="nothing" src={Icon2} /> </div> EVENT  </div>
                    <div className='BodyTextSec2'>  Des circuits panoramiques et l’immersion dans une festivité (fête des couleurs, fête des lumières, foire aux chameaux, etc. )
                    </div>
                    </div> 
                    
                    <div className="Colm"> 
                    <div className='SubHeadSec2'> <div className="IconCont">   <img alt="nothing" src={Icon3} /> </div> EXPERIENCE  </div>
                    <div className='BodyTextSec2'>  Des circuits incluant l’expérience d’une thématique (ex: yoga, ayurveda, partage solidaire.) </div>
                    </div>

                </div>
        </div>

        <div className='Section3'>

            <div className='ColmSec3 Green'>   
            <div className='ImgSec3'> <img  alt="nothing" src={HawaMahal} /> </div>
            <div className='SubheadSec3 '>  RAJASTHAN </div> 
            <div className='ExTitle GreenBg'> DISCOVER </div> 
            <div className=''> 2 Circuits de 8 a Nuits </div> 

            <br />

            <div className='ExTitle GreenBg'> EVENT </div>
            <div className=''> 2 Circuits de 8 a Nuits </div> 

            <br />

            <div className='ExTitle GreenBg'> EXPERIENCE </div> 
            <div className=''> 3 Circuits de 9 a 11 Nuits </div> 
            <div className=''> Trek & Campement </div> 
            </div>



            <div className='ColmSec3 Orange'>    
            <div className='ImgSec3'> <img  alt="nothing" src={SouthIndia} /> </div>
            <div className='SubheadSec3'> INDE DU SUD  </div> 
            <div className='ExTitle OrangeBg'> DISCOVER </div> 
            <div className=''> 2 Circuits de 8 a Nuits </div> 
            <br />
            <div className='ExTitle OrangeBg'> EXPERIENCE </div> 
            <div className=''> 3 Circuits de 9 a 11 Nuits </div> 
            <div className=''> Trek & Campement </div> 
            </div>


            <div className='ColmSec3 Gold'>    

            <div className='ImgSec3'> <img  alt="nothing" src={GoldenTemple} /> </div>
            <div className='SubheadSec3'> PENJAB & INDE TEBETAINE </div> 
            <div className='ExTitle GoldBg'> DISCOVER </div> 
            <div className=''> 2 Circuits de 8 a Nuits </div> 
            <br />
            <div className='ExTitle GoldBg'> EVENT </div> 
            <div className=''> 3 Circuits de 9 a 11 Nuits </div> 
            <div className=''> Trek & Campement </div> 
            </div>


            <div className='ColmSec3 DarkGreen'>    
            
            <div className='ImgSec3'> <img  alt="nothing" src={Nepal} /> </div>
            <div className='SubheadSec3'> NEPAL </div>
            <div className='ExTitle DarkGreenBg'> DISCOVER </div> 
            <div className=''> 2 Circuits de 8 a Nuits </div> 
            <br />
            <div className='ExTitle DarkGreenBg'>  EXPERIENCE </div> 
            <div className=''> 3 Circuits de 9 a 11 Nuits </div> 
            <div className=''> Trek & Campement </div> 

            </div> 




        </div>

        <div className='Section4'> 

            <div className='Head3'> NOTRE HISTORIE EN 2 MOTS  ET 3 MARQUES </div>
            
            <div className='DestGrid1'>  
                    <div className='Dest'> 
                        <div className='Logos1'> <img alt="nothing" src={AuraLogo} /> </div>
                        <div className="Txt4" >  En 2014, après 12 ans de réussite sur le marché français (SITA, Thomas Cook), création de l’agence réceptive AURA, basée à Delhi et representée en France 
                        </div> <br/ >
                        <div > Destinations: Rajasthan, Gujarat, Bengale, Etats de L'Inde du sud, Bhoutan, Nepal, Sri Lanka </div> <br /> <br />
                        <div className="KnowmoreBtCont"> <Link className="KnowmoreBt" to="/"> know more </Link> </div>

                    </div>

                    <div className='Dest'>  
                        <div className='Logos1'> <img alt="nothing"   src={HimLogo}  /> </div>
                        <div className="Txt4" > En 2015, création de la branche réceptive HIMALAYAN, basée à Shimla, Himachal Pradesh.
                        </div> <br/ >
                        <div > Destinations: Himalaya-indien, Himachal Pradesh, Laddakh, Sikkim    </div> <br /> <br />
                        <div className="KnowmoreBtCont"> <Link className="KnowmoreBt" to="/himalayan-voyages"> know more </Link> </div>

                    </div>

                    <div className='Dest'>  
                        <div className='Logos1'> <img alt="nothing"   src={AbledLogo}  /> </div>
                        <div  className="Txt4" >  En 2016, création de la branche réceptive ABLED, basée à Delhi. Public cible : Personnes atteintes de handicap de mobilité
                        </div> <br/ >
                        <div > Destinations: <br /> Rajasthan  </div> <br /> <br />
                        <div className="KnowmoreBtCont"> <Link className="KnowmoreBt"  to="/abled-voyages"> know more </Link>  </div>

                    </div>
                
             </div>


            
             </div>




        <div className='Section5'>


            <div className='ColmSec5'> 
                <div> 
                    <div className='Text5a'> EQUIPE EN INDE </div> <img className='Flag1' alt="nothing" src={FlagInd} /> 
                </div>

                <div className='GridTeam1'> 
                <div className="Text5"> <img className="Team1" alt="nothing" src={T1} /> <br /> Vandana  </div>
                <div className="Text5"> <img className="Team1" alt="nothing" src={T2} /> <br /> Ravinder  </div>
                <div className="Text5"> <img className="Team1" alt="nothing" src={T3} /> <br /> Arun  </div>
                <div className="Text5"> <img className="Team1" alt="nothing" src={T4} /> <br /> Virender  </div>
                <div className="Text5"> <img className="Team1" alt="nothing" src={T5} /> <br /> Rakesh  </div>
                <div className="Text5"> <img className="Team1" alt="nothing" src={T6} /> <br /> Ravinder  </div>
                </div>



            </div>

            <div className='ColmSec5'> 
                <div> 
                    <div className='Text5a'> REPRESENTANT EN FRANCE </div> 
                    <img className='Flag1' alt="nothing" src={FlagFr} /> 
                </div>
                <div className='GridTeam1'> 
                <div className="Text5"> <img className="Team1" alt="nothing" src={T7} /> <br /> Phillipe  </div>
                </div>

            </div>


        </div>


        <div className='Section6'> 

            <div className='FooterCont'> 
            <div> Aura Voyages Pvt. Ltd. </div> <br />
            <div> Registered Address - India: <br /> Flat no. 384, Sunview Apartment, Sector 11, Pocket 4, Dwarka, New Delhi - 110075, INDIA  </div>
            <div> Corporate Contact Email - <div > info@auravoyages.com </div> </div>
            <div> Contact Number: <div> +91 98107 20202 </div> </div> 
            </div>


            </div>
        


        
    </div>






    </>
  )
}

export default France2024